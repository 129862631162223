<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->

            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="end" class="mt-4">

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        :label="$t('report_type')"
                                                        clearable
                                                        single-line
                                                        item-text="id"
                                                        dense
                                                        outlined
                                                        item-value="value"
                                                        @change="changeColumn"></v-autocomplete>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-select v-if="isSaarc"
                                                  :items="customYear"
                                                  :label="$t('from_date')"
                                                  v-model="serverParams.FromDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                        outlined></v-select>
                                        <v-select v-if="!isSaarc"
                                                  :items="customYear"
                                                  :label="$t('select_year')"
                                                  v-model="serverParams.FromDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                        outlined></v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-select v-if="isSaarc"
                                                  :items="customYear"
                                                  :label="$t('to_date')"
                                                  v-model="serverParams.ToDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                        outlined></v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2 mt-3" dark small color="primary" @click="loadItems">
                                            <v-icon dark>search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-10">
                                    <download-excel :fields="excelData.json_fields"
                                                    :fetch="onDownload"
                                                    worksheet="My Worksheet">
                                        <v-btn small class="success mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                    </download-excel>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <component :is="activeComponent" :rows="rows" :columns="columns"></component>


<!--            <vue-good-table :columns="columns"-->
<!--                            :rows="rows"-->
<!--                            mode="remote"-->
<!--                            styleClass="vgt-table condensed">-->
<!--                <template slot="table-row" slot-scope="props">-->
<!--                    <span v-if="props.column.field == 'datedOn'">-->
<!--                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>-->
<!--                    </span>-->
<!--                    &lt;!&ndash;<span v-else-if="props.column.field == 'actions'">-->
<!--                        <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>-->
<!--                        <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>-->
<!--                    </span>&ndash;&gt;-->
<!--                </template>-->
<!--            </vue-good-table>-->
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import downloadExcel from "vue-json-excel";
    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
          isSaarc(){
              return this.serverParams.ReportType != "SaarcWise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.trekking'),
                        exact: true,
                        to: '/reportModules/reportTrekking'
                    },
                    {
                        text: this.$t('modules_name.monthly_yearly_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            },
        },
        components: {
            'SaarcTable': () => import('./modules/SaarcTable'),
            'YearMonthTable': () => import('./modules/YearMonthTable'),
            downloadExcel,
        },
        methods: {
            changeColumn(value) {
                console.log(value)
            },
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            goBackToReportDashboard() {
                this.$emit('goBackToReportDashboard')
            },
            goBackToReportSelection() {
                this.$emit('goBackToReportSelection')
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                this.excelData= {
                    loading: false,
                        json_fields: { },
                    json_data: [],
                        json_meta: [
                            [
                                {
                                    key: "charset",
                                    value: "utf-8",
                                },
                            ],
                        ],
                }
                let param = {
                    ReportType: this.serverParams.ReportType,
                    FromDate: this.serverParams.FromDate + '-01-01',
                    ToDate: this.serverParams.ToDate + '-12-31'
                };
                if (this.serverParams.ReportType == "SaarcWise") {

                    await axios({
                        url: 'Trekking/DownloadTrekkingSaarcNonSaarcReport',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'TrekkingSaarcNonSaarcReport.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else if (this.serverParams.ReportType === 'yearwise') {

                    await axios({
                        url: 'Trekking/DownloadTrekkingReport',
                        method: 'POST',
                        data: param
                    }).then((response) => {
                        console.log('pp', response)
                        this.excelData.json_fields['TravelYear'] = 'TravelYear'
                        response.data.header.map(res => {
                            this.excelData.json_fields[res.label] = res.field
                        })
                        this.excelData.json_fields['Total'] = 'Total'
                        this.excelData.json_data = response.data.dataList
                    });
                    return this.excelData.json_data
                }
                else {

                    await axios({
                        url: 'Trekking/DownloadTrekkingReport',
                        method: 'POST',
                        data: param
                    }).then((response) => {
                        this.excelData.json_fields['TravelMonth'] = 'TravelMonth'
                        response.data.header.map(res => {
                            this.excelData.json_fields[res.label] = res.field
                        })
                        this.excelData.json_fields['Total'] = 'Total'
                        this.excelData.json_data = response.data.dataList
                    });
                    return this.excelData.json_data
                }
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'TrekkingFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },

            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                this.serverParams.ReportType == "SaarcWise"?this.activeComponent = "SaarcTable":this.activeComponent = "YearMonthTable"

                let param = {}
                let url=""
                if(this.serverParams.ReportType == "SaarcWise"){
                    param = {
                        ReportType: this.serverParams.ReportType,
                        FromDate: this.serverParams.FromDate + '-01-01',
                    };
                    url="Trekking/GetTrekkingSaarcNonSaarcReport"
                }else{
                    param = {
                        ReportType: this.serverParams.ReportType,
                        FromDate: this.serverParams.FromDate + '-01-01',
                        ToDate: this.serverParams.ToDate + '-12-31'
                    };
                    url = "Trekking/GetTrekkingReport"
                }

                axios.post(url, param)
                    .then(response =>{
                        if(this.serverParams.ReportType == "SaarcWise"){
                            this.rows = response.data;
                        }
                        else{
                            this.rows = response.data.dataList;
                            console.log('response', response)
                            this.totalRecords = response.data.totalCount;
                            this.columns = response.data.header
                            if (this.serverParams.ReportType === 'yearwise') {
                                this.columns.unshift({
                                    label: this.$t('year'),
                                    field: 'TravelYear'
                                })
                            }
                            if (this.serverParams.ReportType === 'monthwise') {
                                this.columns.unshift({
                                    label: this.$t('month'),
                                    field: 'TravelMonth'
                                })
                            }
                            this.columns.push({
                                label: this.$t('total'),
                                field: 'Total'
                            })
                            this.snackbar.loading = false;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });

            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
        },
        data() {
            return {
                excelData: {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                },
                activeComponent:"",
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [],
                rows: [],
                ReportTypes: [
                    {
                        id: this.$t('year_wise'), value: "yearwise"
                    },
                    {
                        id: this.$t('month_wise'), value: "monthwise"
                    },

                     {
                         id: this.$t('saarcwise'), value: "SaarcWise"
                    },

                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                }
            }
        }
    }
</script>
